.om {
    min-height: 40vh;
    margin: 25px 0 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.om h2 {
    margin-bottom: 0;
    text-align: center;
}

.om p {
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.5px;
}

.om a {
    color: #000000;
}

.om a:hover {
    text-decoration: underline;
  }
  
.catering {
    margin: 100px 0 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.us {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f7f4e9;
    padding: 25px;
    margin: 25px 0;
}

.usText {
    width: 100%;
}

.usImage {
    height: 250px;
    width: 250px;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
    background-position: center;
    background-size: cover;
}

@media screen and (min-width: 820px) {
    .om {
        padding: 0 200px;
    }

    .catering {
     margin: 100px 0 50px 0;
    }

    .us {
        padding: 50px 150px;
        margin: 50px 0;
        flex-direction: row;
    }

    .usText {
        width: 40%;
    }
}