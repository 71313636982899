.menu {
  background-color: #cccccc;
  color: #000000;
  height: 250px;
  width: 100%;
  margin: 25px 0 150px 0;
  background-size: cover;
  background-position: center;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}

.menu h2 {
  font-size: 22px;
  margin: 2px 0;
}

.menuBox {
  color: #000000;
  padding: 15px 25px 15px 0;
  width: 100%;
}

.menuBox h2 {
  text-decoration: none;
}

.menuBox:hover {
  border-width: 5px;
}

.menuBox button {
  background-color: #000000;
}

a {
  text-decoration: none;
}

.menuContainer {
  height: 70vh;
  position: relative;
  margin-top: 40px;
  width: 100%;
}

.imageContainer {
  border-radius: 10px;
  width: 250px;
  height: 150px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.image1,
.image2,
.image3 {
  position: absolute;
}

.image1 {
  width: 250px;
  height: 200px;
  left: 0;
  right: 0;
  margin: auto;
}

.image2 {
  top: -40px;
  left: 0;
  width: 200px;
  height: 100px;
}

.image3 {
  top: 160px;
  right: 0;
  width: 200px;
  height: 100px;
}

@media screen and (min-width: 820px) {
  .container {
    width: 100%;
    min-height: 50vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 40px;
    margin: 0;
    padding: 50px 0;
  }

  .menuBox {
    width: 40%;
  }

  .menuContainer {
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .menuContainer {
    margin: 0;
  }

  .image1 {
    position: relative;
    width: 350px;
    height: 270px;
  }

  .image2 {
    left: -45px;
    top: 70px;
    height: 150px;
  }

  .image3 {
    top: 215px;
    right: -30px;
    width: 200px;
    height: 110px;
  }
}
