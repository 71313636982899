* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

button {
  background-color: #BABB77;
  color: white;
  padding: 10px 15px;
  border: none;
  width: fit-content;
  font-size: 16px;
}

.buttonLink {
  font-weight: bold;
  color: #BABB77;
}

.page {
  min-height: 35vh;
  padding: 0 150px;
}

.pageNoHeight {
  min-height: 0;
}

.page h4 {
  margin-bottom: 4px;
}

.page p {
  margin-top: 4px;
}

.shortHero {
  min-height: 15vh;
  margin-top: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.center p {
  margin: 0 0 45px 0;
}

.projectLogo:first-child {
  margin-right: 15px;
}

@media screen and (max-width: 1024px) {
  .page {
    padding: 0 25px;
  }

  .center {
    text-align: center;
  }
}