.hero {
  display: none;
}

.heroMobile {
  min-height: 90vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px 20px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  overflow: hidden;
}

.logo {
  width: 240px;
}

@media screen and (min-width: 820px) {
  .hero {
    /* height: 90vh; */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px;
  }

  .heroMobile {
    display: none;
  }

  .logo {
    width: 300px;
  }

  .menu {
    background-position: center;
  }

  .hero h1 {
    font-size: 48px;
    color: #222222;
  }
  .logo {
    width: 300px;
  }
}
