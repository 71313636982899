.galleryWrapper {
    max-width: 1200px;
    position: relative;
    margin-bottom: 50px;
}

.galleryButton {
    top: 50%;
    height: 44px;
    width: 44px;
    text-align: center;
    font-size: 22px;
    line-height: 44px;
    background: rgb(255, 255, 255);
    border-radius: 50%;
    position: absolute;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    cursor: pointer;
}

.galleryButton:first-child {
    left: 10px;
}

.galleryButton:last-child {
    right: 10px;
}

.galleryButton i {
    color: #000000;
    margin: 0;
}

.gallery {
    white-space: nowrap;
    font-size: 0;
    overflow: hidden;
    overflow-x: scroll;
    scroll-behavior: smooth;
    margin-bottom: 25px;
}

.galleryImage {
    height: 340px;
    object-fit: cover;
    width: calc(100% / 3);
    display: inline-block;
    margin-left: 10px;
}

.galleryImage:first-child {
    margin-left: 0;
}

@media screen and (max-width: 820px) {

    .galleryWrapper {
        margin-bottom: 0;
    }

    .galleryImage {
        width: 100%;
        margin-left: 0;
    }

    .galleryButton {
        height: 36px;
        width: 36px;
        line-height: 36px;
        font-size: 18px;
    }
}