.puffs {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    color: #ffffff;
    padding-top: 28vh;
    padding: 35px 0 0 0;
}

.puff {
    width: 100%;
    text-align: center;
}

.puffsSection {
    display: flex;
    align-items: center;
    justify-content: center;
}

.arrow {
    color: #ffffff;
    margin-left: 5px;
    width: 5px;
}

.puffLink {
    color: #ffffff;
}

.puffLinkHover:hover {
    text-decoration: underline;
}

.puffBooking p {
    margin-bottom: 0px;
}

@media screen and (min-width: 820px) {
    .puffs {
        flex-direction: row;
        justify-self: flex-end;
        margin-top: 200px;
        padding: 0 20px;
    }
    
    .puff {
        width: 200px;
    }

    .puff:nth-child(2) {
        width: 340px;
    }

    .puffBooking {
        width: 280px;
    }
}